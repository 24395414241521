import React from 'react'
import { Link } from 'gatsby'
import { LOCAL_DATA } from '../config/siteConfi';
import Modal from './ui/Modal';
import MobileNav from './ui/MobileNav';
import { contentUrl } from '../lib/db';
import { useContent } from '../hooks/useContent';

const NavBar = () => {
   const { isLoading, error, data } = useContent(contentUrl);

   const NAVITEMS: string[] = (data?.siteMenu?.length ?? 0) > 0 ? data.siteMenu : LOCAL_DATA.siteMenu;

   const logo: string = data?.logotype ? data.logotype : LOCAL_DATA.logotype

   return (
      <header className='py-6'>
         <nav className="container flex items-center justify-between">
            <Link to='/'>
               <img src={logo} width={90} height={90} alt={`${process.env.GATSBY_SITE_NAME} logo`} />
            </Link>
            <div className='hide-items flex items-center gap-6'>
               {NAVITEMS.map((item, index) => (
                  <Link to={`/${index === 0 ? '' : item.toLowerCase()}`} key={index}>{item}</Link>
               ))}
            </div>
            <div className='sm:hidden'>
               <MobileNav />
            </div>
         </nav>
      </header>
   )
}

export default NavBar;