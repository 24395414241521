import {
  bg1,
  bg4,
  bg5,
  bg6,
  box2,
  box3,
  handePhone,
  img1,
  img2,
  img3,
  img4,
  logo,
  megaPhone,
  user1,
  user2,
  user3,
} from '../images';
import { ILocalData } from '../interface/data';

export const primary = '';
export const secondary = '';
export const action = '';
export const COLORS = {
   primary: '',
   secondary: '',
};
export const squareData = [
  {
    id: 1,
    src: img1,
  },
  {
    id: 2,
    src: img2,
  },
  {
    id: 3,
    src: img3,
  },
  {
    id: 4,
    src: img4,
  },
  {
    id: 5,
    src: 'https://images.unsplash.com/photo-1569074187119-c87815b476da?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1325&q=80',
  },
  {
    id: 6,
    src: 'https://images.unsplash.com/photo-1556817411-31ae72fa3ea0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
  },
  {
    id: 7,
    src: 'https://images.unsplash.com/photo-1599586120429-48281b6f0ece?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
  },
  {
    id: 8,
    src: 'https://plus.unsplash.com/premium_photo-1671436824833-91c0741e89c9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
  },
  {
    id: 9,
    src: 'https://images.unsplash.com/photo-1431324155629-1a6deb1dec8d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
  },
  {
    id: 10,
    src: 'https://images.unsplash.com/photo-1610768764270-790fbec18178?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80',
  },
  {
    id: 11,
    src: 'https://images.unsplash.com/photo-1507034589631-9433cc6bc453?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=684&q=80',
  },
  {
    id: 12,
    src: 'https://images.unsplash.com/photo-1533107862482-0e6974b06ec4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=882&q=80',
  },
  {
    id: 13,
    src: 'https://images.unsplash.com/photo-1560089000-7433a4ebbd64?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
  },
  {
    id: 14,
    src: 'https://images.unsplash.com/photo-1517466787929-bc90951d0974?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=686&q=80',
  },
  {
    id: 15,
    src: 'https://images.unsplash.com/photo-1606244864456-8bee63fce472?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=681&q=80',
  },
  {
    id: 16,
    src: 'https://images.unsplash.com/photo-1544367567-0f2fcb009e0b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1820&q=80',
  },
];
export const siteConfi = {
  navLinks: [
    {
      id: 1,
      title: 'Home',
      href: '/',
    },
    {
      id: 2,
      title: 'Soluções de marketing',
      href: '/servicos',
    },
    {
      id: 3,
      title: 'Quem somos',
      href: '/sobre',
    },
    {
      id: 4,
      title: 'Projectos',
      href: '/projectos',
    },
    {
      id: 5,
      title: 'Contacto',
      href: '/contacto',
    },
  ],
};
export const APIDATA: [] = [];
export const LOCAL_DATA = {
   logotype: logo,
   headerBanner: [megaPhone],
   siteMenu: ['Home','Sobre','Servicos', 'Projectos', 'Noticias', 'contacto'],
   headerSection: [
      {
         title: 'Marketing digital que gera receita',
         subTitle: 'É a sua vez de brilhar quando colocamos em destaque a sua marca. Ajudamos a obter a atenção e as receitas que o seu negócio merece no mundo digital.',
         description: '',
      }
   ],
   about: [
      {
         _id: '1',
         thumbnail: [box2, box3, box2],
         title: 'Promovendo uma maneira melhor de fazer marketing',
         description: '',
         subTitle: 'Juntos, ajudamos nossos clientes a alcançar resultados tangíveis e mensuráveis. Focados nos resultados de negócios — trazemos um conjunto único de conhecimentos e habilidades para a festa.',
         
      },
      {
         _id: '2',
         thumbnail: [handePhone],
         title: 'Nos Combinamos os dados do cliente com Trabalho Efectivo',
         subTitle:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis voluptas quaerat similique debitis esse, cupiditate tenetur, velit, nostrum saepe alias id enim tempora laudantium optio minus maiores provident molestiae iure.',
         description: ''
      },
   ],

   services: [
      {
         thumbnail: [],
         title: 'Nos ajudamos marcas a trazerem uma nova vida na era digital',
         subTitle: '',
         description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius ipsam quo obcaecati voluptates dolorem qui ullam at totam optio vero vel quisquam animi officiis esse praesentium incidunt, rem itaque, maxime saepe nihil sit? Autem iure repudiandae deleniti ullam consectetur numquam.',
      },
      {
         thumbnail: ['https://images.unsplash.com/photo-1631451095765-2c91616fc9e6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80'],
         title: 'Crequalme criative Go',
         subTitle: 'Criar & Otimizar',
         description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius ipsam quo obcaecati voluptates dolorem qui ullam at totam optio vero vel quisquam animi officiis esse praesentium incidunt, rem itaque, maxime saepe nihil sit? Autem iure repudiandae deleniti ullam consectetur numquam.',
      },
      {
         thumbnail: ['https://images.unsplash.com/photo-1631451095765-2c91616fc9e6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80'],
         title: 'Crequalme criative Go',
         subTitle: 'Criar & Otimizar',
         description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius ipsam quo obcaecati voluptates dolorem qui ullam at totam optio vero vel quisquam animi officiis esse praesentium incidunt, rem itaque, maxime saepe nihil sit? Autem iure repudiandae deleniti ullam consectetur numquam.',
      },
      {
         thumbnail: ['https://images.unsplash.com/photo-1631451095765-2c91616fc9e6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80'],
         title: 'Crequalme criative Go',
         subTitle: 'Criar & Otimizar',
         description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius ipsam quo obcaecati voluptates dolorem qui ullam at totam optio vero vel quisquam animi officiis esse praesentium incidunt, rem itaque, maxime saepe nihil sit? Autem iure repudiandae deleniti ullam consectetur numquam.',
      },
   ],
   team: [
      {
         id: '1',
         thumbnail:user1,
         name: 'Hanna Pedro',
         jobPosition: 'CEO & Founde',
      },
      {
         id: '2',
         thumbnail:user2,
         name: 'Maria Alvez',
         jobPosition: 'CEO & Founde',
      },
      {
         id: '3',
         thumbnail:user3,
         name: 'Mario Apolo',
         jobPosition: 'CEO & Founde',
      },
   ],
  
   portfolio: [
      {
         _id: '1',
         thumbnail: bg4,
         title: 'Abra um novo Horizonte para seu Négocio',
         subTitle: 'Quando você faz parceria com a Numerique, nós cuidamos do trabalho pesado, para que você possa aproveitar mais tráfego, leads e receita do site.',
         description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sequi debitis suscipit dicta, excepturi hic tempore reiciendis deserunt quisquam dolorum veniam enim consequatur pariatur modi ut sunt rerum ad ea dolore.',
         url: '1',
         createdAt: new Date()
      },
      {
         _id: '2',
         thumbnail: bg4,
         title: 'Crequalme criative Go',
         subTitle: 'Criar & Otimizar',
         description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sequi debitis suscipit dicta, excepturi hic tempore reiciendis deserunt quisquam dolorum veniam enim consequatur pariatur modi ut sunt rerum ad ea dolore.',
         url: '1',
         createdAt: new Date()
      },
      {
         _id: '3',
         thumbnail: bg4,
         title: 'Crequalme criative Go',
         subTitle: 'Criar & Otimizar',
         description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sequi debitis suscipit dicta, excepturi hic tempore reiciendis deserunt quisquam dolorum veniam enim consequatur pariatur modi ut sunt rerum ad ea dolore.',
         url: '1',
         createdAt: new Date()
      },
      {
         _id: '4',
         thumbnail: bg4,
         title: 'Crequalme criative Go',
         subTitle: 'Criar & Otimizar',
         description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sequi debitis suscipit dicta, excepturi hic tempore reiciendis deserunt quisquam dolorum veniam enim consequatur pariatur modi ut sunt rerum ad ea dolore.',
         url: '1',
         createdAt: new Date()
      },
      {
         _id: '5',
         thumbnail: bg6,
         title: 'Crequalme criative Go',
         subTitle: 'Criar & Otimizar',
         description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sequi debitis suscipit dicta, excepturi hic tempore reiciendis deserunt quisquam dolorum veniam enim consequatur pariatur modi ut sunt rerum ad ea dolore.',
         url: '1',
         createdAt: new Date()
      },
      {
         _id: '6',
         thumbnail: bg4,
         title: 'Crequalme criative Go',
         subTitle: 'Criar & Otimizar',
         description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sequi debitis suscipit dicta, excepturi hic tempore reiciendis deserunt quisquam dolorum veniam enim consequatur pariatur modi ut sunt rerum ad ea dolore.',
         url: '1',
         createdAt: new Date()
      },
      {
         _id: '7',
         thumbnail: bg5,
         title: 'Crequalme criative Go',
         subTitle: 'Criar & Otimizar',
         description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sequi debitis suscipit dicta, excepturi hic tempore reiciendis deserunt quisquam dolorum veniam enim consequatur pariatur modi ut sunt rerum ad ea dolore.',
         url: '1',
         createdAt: new Date()
      },
   ],
   testimonial: [
      {
         _id: '1',
         photo: img1,
         name: 'Matthias Leidinger',
         position: '',
         color: "#BBACAF",
         message: 'Originally hailing from Austria, Berlin-based photographer Matthias Leindinger is a young creative brimming with talent and ideas.',
      },
      {
         _id: '2',
         photo: img2,
         name: 'Clément Chapillon',
         position: '',
         color: "#977F6D",
         message: 'This is a story on the border between reality and imaginary, about the contradictory feelings that the insularity of a rocky, arid, and wild territory provokes”—so French photographer Clément Chapillon describes his latest highly captivating project Les rochers fauves (French for ‘The tawny rocks’).',
      },
      {
         _id: '3',
         photo: img3,
         name: 'Zissou',
         position: '',
         color: "#C2491D",
         message: 'Though he views photography as a medium for storytelling, Zissou’s images don’t insist on a narrative. Both crisp and ethereal, they’re encoded with an ambiguity—a certain tension—that lets the viewer find their own story within them.',
      },
      {
         _id: '4',
         photo: img3,
         name: 'Zissou',
         position: '',
         color: "#B62429",
         message: 'Though he views photography as a medium for storytelling, Zissou’s images don’t insist on a narrative. Both crisp and ethereal, they’re encoded with an ambiguity—a certain tension—that lets the viewer find their own story within them.',
      },
      {
         _id: '5',
         photo: img3,
         name: 'Zissou',
         position: '',
         color: "#88A28D",
         message: 'Though he views photography as a medium for storytelling, Zissou’s images don’t insist on a narrative. Both crisp and ethereal, they’re encoded with an ambiguity—a certain tension—that lets the viewer find their own story within them.',
      },
   ],
   contact: {
      phone: ['(+244)912 345 6789', '(+244)912 345 6789'],
      whatsapp: 'https://web.whatsapp.com',
      facebook: 'https://www.facebook.com',
      twitter: 'https://x.com/home',
      linkedIn: 'https://www.linkedin.com',
      instagram: '',
      email: ['info@exemol'],
      youtube: 'https://www.youtube.com/',
      address: '5919 Trussville St, Birmingham, America',
   },
   eventes: [
      {
         _id: '1',
         thumbnail: img1,
         title: 'Digital Marketing: Staying Relevant To The Empowered Consumer',
         subTitle: 'Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.',
         description: 'Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.',
         category: ['Psicologia', 'Agencia', 'Parceria'],
         createdAt: new Date()
      },
      {
         _id: '2',
         thumbnail: img2,
         title: 'Grow Your Business With A Digital Marketing Strategy',
         subTitle: 'Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.',
         description: 'Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.',
         category: ['Psicologia', 'Agencia', 'Marketing digital'],
         createdAt: new Date()

      },
      {
         _id: '3',
         thumbnail: img3,
         title: '5 Inexpensive Online Tools To Get Your Business Off The Ground',
         subTitle: 'Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.',
         description: 'Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed do eiusmod tempory maecenas accumsan lacus facilisis.',
         category: ['SEO orgânico de longo prazo', 'Agencia', 'Parceria', 'Contacto'],
         createdAt: new Date()
      },
   ],
   
   footer: {
      logo: '',
      logoName: 'Vestil',
      description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatum iusto',
      subDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatum iusto Lorem,',
      contactLable: 'Contactos',
      phoneNumber: 923456789,
      email: '@exemple.com'
   }
}
