import { useQuery } from "@tanstack/react-query";
import { getContent } from "../lib/query";

export const useContent = (url: string) => {
   return useQuery({
      queryKey: ["content"],
      queryFn: () => getContent(url),
      staleTime: 60000, // Data is fresh for 1 minute
      refetchOnWindowFocus: true, // Refetch on window focus
      refetchOnReconnect: true, // Refetch on reconnect
   });
};